import React from "react"
import { Container, Paper, Divider } from "@material-ui/core"
import Navbar from "../components/sections/Navbar"
import ContactUsSection from "../components/sections/ContactUsSection"
import Hero from "../components/sections/Hero"
// import {} from "../components/sections/Hero"

const AboutPage = () => (
  <div className="basic-template page-about">
    <Navbar />
    <Hero title="About" />
    <Container size="lg" className="basic-template__content-wrapper">
      <Paper elevation={0}>
        <div className="basic-template__content-container">
        oijoij
        </div>
      </Paper>
    </Container>
    <ContactUsSection />
  </div>
)

export default AboutPage
